<template>
  <div class="topbar-item">
    <div
        class="d-flex align-items-center px-2 pointer"
        id="kt_quick_user_toggle"
    >
      <span
          class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ currentUser.firstName }} {{ currentUser.lastName }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img alt="Pic" :src="currentUser.photo"/>
      </span>
    </div>

    <div
        id="kt_quick_user"
        ref="kt_quick_user"
        class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Content-->
      <perfect-scrollbar
          class="offcanvas-content pr-5 mr-n5 scroll"
          style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img
                class="symbol-label"
                :src="currentUser.photo"
                alt=""
            />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <a :href="this.$envConfig.ACCOUNTS_URL + '/user/personal-details'"
                class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ currentUser.firstName }} {{ currentUser.lastName }}
            </a>
            <div class="navi mt-2">
              <div class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                          src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ currentUser.email }}
                  </span>
                </span>
              </div>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
             Logout
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">

          <div class="navi-link mb-3">
            <div class="navi-text">
              <a :href="this.$envConfig.ACCOUNTS_URL + '/user/personal-details'" class="navi-item">
                <i class="m-nav__link-icon flaticon-profile-1"></i>
                <span class="font-weight-bold ml-2">User Profile</span>
              </a>
            </div>
          </div>
          <div class="navi-link mb-3">
            <a :href="this.$envConfig.ACCOUNTS_URL + '/company/general-info'" class="navi-item">
              <div class="navi-text">
                <i class="m-nav__link-icon flaticon-network"></i>
                <span class="font-weight-bold ml-2">Company Profile</span>
              </div>
            </a>
          </div>

        </div>
        <!--end::Nav-->
        <div class="separator separator-dashed my-7"></div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
@media (max-width: 576px) {
  .offcanvas.offcanvas-right.offcanvas-on {
    top:30px;
  }
}
</style>

<script>
import {mapGetters} from "vuex";
import {LOGOUT} from "corePath/services/store/auth.module";
import KTLayoutQuickUser from "assetsPath/js/layout/extended/quick-user.js";
import {pageRedirect} from "utilPath/helper";

export default {
  name: "KTQuickUser",
  data() {
    return {};
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store
          .dispatch(LOGOUT)
          .then(() => pageRedirect(this.$envConfig.ACCOUNTS_URL + '/user/logout'));
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  }
};
</script>
