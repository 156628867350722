<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">

    <!--begin: Language bar -->
    <div class="topbar-item language">
      <b-dropdown
          size="sm"
          variant="link"
          toggle-class="mr-1 text-decoration-none"
          no-caret
          right
          no-flip
      >
        <template v-slot:button-content>
          <img
              class="h-25px w-25px rounded-sm"
              :src="languageFlag || getLanguageFlag"
              alt=""
          />
        </template>
        <b-dropdown-text tag="div" class="min-w-md-175px">
          <KTDropdownLanguage
              v-on:language-changed="onLanguageChanged"
          ></KTDropdownLanguage>
        </b-dropdown-text>
      </b-dropdown>
    </div>
    <!--end: Language bar -->

    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.language button {
  border:0px !important;
  box-shadow: none !important;
}
.language button:hover {
  box-shadow: none !important;
}
.topbar {
  .dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTDropdownLanguage from "viewVuePath/layout/extras/dropdown/DropdownLanguage.vue";
import KTQuickUser from "viewVuePath/layout/extras/offcanvas/QuickUser.vue";
import i18nService from "corePath/services/i18n.service.js";
import {mapGetters} from "vuex";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages
    };
  },
  components: {
    KTDropdownLanguage,
    KTQuickUser
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    }
  },
  computed: {
    ...mapGetters(["currentCompany"]),
    getLanguageFlag() {
      return this.onLanguageChanged();
    }
  }
};
</script>
