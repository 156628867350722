const i18nService = {
  defaultLanguage: "en_US",

  languages: [
    {
      lang: "en_US",
      name: "English",
      country: "",
      flag: process.env.BASE_URL + "media/flags/us.svg"
    },
    {
      lang: "tr_TR",
      name: "Türkçe",
      flag: process.env.BASE_URL + "media/flags/tr.svg"
    }
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage(lang) {
    localStorage.setItem("language", lang);
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {
    return localStorage.getItem("language") || this.defaultLanguage;
  },

  /**
   * @param countryCode
   * @returns {string}
   */
  getCountryFlag(countryCode) {
    return window.$envConfig.BASE_URL + '/media/flags/'+countryCode.toLowerCase()+'.svg';
  }
};

export default i18nService;
